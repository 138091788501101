<template>
  <v-card
    class="ma-5"
    :elevation="0"
    color="grey-lighten-4"
    flat
    outlined
    :loading="loading"
  >
    <v-toolbar color="primary" :dark="true">
      <v-toolbar-title>My Ballot Boxes</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="pa-3">
      <v-chip-group selected-class="text-primary" column>
        <v-chip v-for="m in campaigns" :key="m.id" @click="campaign_changed(m)">
          {{ m.name }}
        </v-chip>
      </v-chip-group>
      <v-list lines="three">
        <v-list-item v-for="item in boxes" :key="item.id">
          <div class="row mb-4">
            <div class="col-12">
              <div class="row">
                <div class="col-12 pb-0 title">
                  {{ item.name }} - (Total Voters: {{ item.voters_count }})
                </div>
                <div
                  class="col-12 pt-0 pb-0"
                  style="max-height: 75px; overflow: hidden"
                >
                  Island: {{ item.island }}
                  <br />
                  Atoll: {{ item.atoll }}
                  <br />
                  Country: {{ item.country }}
                  <br />
                </div>
                <v-btn
                  depressed
                  color="primary"
                  @click="loginToBallotBox(item)"
                >
                  Login to Ballot Box
                </v-btn>
              </div>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  created() {
    this.getCampaigns();
  },
  components: {},
  data() {
    return {
      valid: true,
      loading: false,
      boxes: [],
      campaigns: [],
    };
  },
  methods: {
    campaign_changed(campaign) {
      if (!this.empty(campaign)) {
        this.campaigns_id = campaign.id;
        this.getBoxes();
      }
    },
    getBoxes() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api("public/my-ballot-boxes", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.boxes = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCampaigns() {
      this.loading = true;
      let f = {};
      this.api("public/campaigns", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.campaigns = response.data;
            if (this.campaigns.length == 1) {
              this.campaign_changed(this.campaigns[0]);
            }
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loginToBallotBox(box) {
      localStorage.setItem("cm_my_box", JSON.stringify(box));
      document.location.href = "/update-votes";
    },
  },
  name: "Voting",
};
</script>
